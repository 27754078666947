import React from "react"

import {MainLayout} from "../components/layout"

// CSS Modules
import bookStyle from "../styles/book.module.styl"
import shared from "../styles/shared.module.styl"

import { BOOK_IMG_MAP, BOOK_BANNER_MAP } from "../pages/libros";

import {PencilRulerIcon, SimpleShopLink, SimpleAmazonShopLink, StoreIcon} from "../components/fa";
import { Link } from "gatsby";

class Blurb extends React.Component {
  render() {
    const {text, source} = this.props;
    return (
      <div className={bookStyle.detailData__blurb}>
        <blockquote cite={source}>
          <h3 className={bookStyle.text}>{text}</h3>
        </blockquote>
      </div>
    );
  }
}

export default class FakeBookDetailPage extends React.Component {


  // componentDidMount() {
  //   if (window.ShopifyBuy) {
  //     if (window.ShopifyBuy.UI) {
  //       this.ShopifyBuyInit();
  //     } else {
  //       this.loadScript();
  //     }
  //   } else {
  //     this.loadScript();
  //   }
  // }
  //
  // loadScript() {
  //   var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
  //   var script = document.createElement('script');
  //   script.async = true;
  //   script.src = scriptURL;
  //   (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
  //   script.onload = this.ShopifyBuyInit;
  // }
  //
  // ShopifyBuyInit() {
  //   var client = window.ShopifyBuy.buildClient({
  //     domain: 'dharma-books-publishing.myshopify.com',
  //     storefrontAccessToken: 'd059ed8cd9f469938ec83ea5fe71c9a5',
  //   });
  //   window.ShopifyBuy.UI.onReady(client).then(function (ui) {
  //     ui.createComponent('product', {
  //       id: '6206973214914',
  //       node: document.getElementById('product-component-1611639279282'),
  //       moneyFormat: '%24%20%7B%7Bamount%7D%7D',
  //       options: {
  //         "product": {
  //           "styles": {
  //             "product": {
  //               "@media (min-width: 601px)": {
  //                 "max-width": "calc(25% - 20px)",
  //                 "margin-left": "20px",
  //                 "margin-bottom": "50px"
  //               }
  //             },
  //             "button": {
  //               "font-family": "Quantico, sans-serif",
  //               "font-weight": "bold",
  //               "font-size": "14px",
  //               "padding-top": "15px",
  //               "padding-bottom": "15px",
  //               ":hover": {
  //                 "background-color": "#dab120"
  //               },
  //               "background-color": "#f2c524",
  //               ":focus": {
  //                 "background-color": "#dab120"
  //               },
  //               "border-radius": "0px"
  //             },
  //             "quantityInput": {
  //               "font-size": "14px",
  //               "padding-top": "15px",
  //               "padding-bottom": "15px"
  //             }
  //           },
  //           "contents": {
  //             "img": false,
  //             "title": false,
  //             "price": false
  //           },
  //           "text": {
  //             "button": "Agregar al carrito"
  //           },
  //           "googleFonts": [
  //             "Quantico"
  //           ]
  //         },
  //         "productSet": {
  //           "styles": {
  //             "products": {
  //               "@media (min-width: 601px)": {
  //                 "margin-left": "-20px"
  //               }
  //             }
  //           }
  //         },
  //         "modalProduct": {
  //           "contents": {
  //             "img": false,
  //             "imgWithCarousel": true,
  //             "button": false,
  //             "buttonWithQuantity": true
  //           },
  //           "styles": {
  //             "product": {
  //               "@media (min-width: 601px)": {
  //                 "max-width": "100%",
  //                 "margin-left": "0px",
  //                 "margin-bottom": "0px"
  //               }
  //             },
  //             "button": {
  //               "font-family": "Raleway, sans-serif",
  //               "font-weight": "bold",
  //               "font-size": "14px",
  //               "padding-top": "15px",
  //               "padding-bottom": "15px",
  //               ":hover": {
  //                 "background-color": "#dab120"
  //               },
  //               "background-color": "#f2c524",
  //               ":focus": {
  //                 "background-color": "#dab120"
  //               },
  //               "border-radius": "0px"
  //             },
  //             "quantityInput": {
  //               "font-size": "14px",
  //               "padding-top": "15px",
  //               "padding-bottom": "15px"
  //             }
  //           },
  //           "googleFonts": [
  //             "Raleway"
  //           ],
  //           "text": {
  //             "button": "Add to cart"
  //           }
  //         },
  //         "cart": {
  //           "styles": {
  //             "button": {
  //               "font-family": "Raleway, sans-serif",
  //               "font-weight": "bold",
  //               "font-size": "14px",
  //               "padding-top": "15px",
  //               "padding-bottom": "15px",
  //               ":hover": {
  //                 "background-color": "#dab120"
  //               },
  //               "background-color": "#f2c524",
  //               ":focus": {
  //                 "background-color": "#dab120"
  //               },
  //               "border-radius": "0px"
  //             }
  //           },
  //           "text": {
  //             "total": "Subtotal",
  //             "button": "Checkout"
  //           },
  //           "googleFonts": [
  //             "Raleway"
  //           ]
  //         },
  //         "toggle": {
  //           "styles": {
  //             "toggle": {
  //               "font-family": "Raleway, sans-serif",
  //               "font-weight": "bold",
  //               "background-color": "#f2c524",
  //               ":hover": {
  //                 "background-color": "#dab120"
  //               },
  //               ":focus": {
  //                 "background-color": "#dab120"
  //               }
  //             },
  //             "count": {
  //               "font-size": "14px"
  //             }
  //           },
  //           "googleFonts": [
  //             "Raleway"
  //           ]
  //         }
  //       },
  //     });
  //   });
  // }

  render() {
    const bookCover = BOOK_IMG_MAP["nancy"];
    const banner = BOOK_BANNER_MAP["nancy"];

    return (
      <MainLayout siteTitle="Prueba paypal">
        <div className={bookStyle.detail}>
          <div className={bookStyle.detail__banner}
               style={{ backgroundImage: `url(${banner})` }}>
          </div>
          <img alt="Nancy" className={bookStyle.detail__book} src={bookCover} />
        </div>
        <div className={shared.container}>
          <div className={bookStyle.detailData__main}>
            <h1>Nancy</h1>
            <Link to={`/autor/bruno-lloret`}><h2>Bruno Lloret</h2></Link>
            <div className={bookStyle.detailBuy}>
              {/*<PaypalButton*/}
              {/*  title={"Nancy"}*/}
              {/*  price={0.01}*/}
              {/*  visible*/}
              {/*/>*/}
              {/*<div id='product-component-1611639279282'></div>*/}
              {/*<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">*/}
              {/*  <input type="hidden" name="cmd" value="_s-xclick"/>*/}
              {/*  <input type="hidden" name="hosted_button_id" value="WGPH5Z8ASJEDW"/>*/}
              {/*  <input type="image" src="https://www.paypalobjects.com/es_XC/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal, la forma más segura y rápida de pagar en línea." />*/}
              {/*</form>*/}

              <SimpleAmazonShopLink
                to="https://amazon.com.mx" />
              <SimpleShopLink icon={<StoreIcon to={"/puntos_de_venta"} />}
                              storeName={"Puntos de venta"}
                              to={"/puntos_de_venta"} />
            </div>
          </div>

          <Blurb
            text={"Lloret, un narrador jovencísimo, entiende mejor que muchos de sus mayores la dimensión trágica de la existencia humana."}
            source="Tal Pinto, The Clinic" />

          <div className={bookStyle.detailData__description}>
            <p>Desde un lenguaje coloquial, Lloret hunde al lector en una atmósfera de muerte, miseria, opresión religiosa, migración, condena y desaparición. Su escritura trágica se mezcla también con un humor sórdido frente a lo grotesco e inquietante; el autor escarba en llagas que la misma Nancy tiene en constante apertura y cicatrización.</p>
          </div>

          <div className={bookStyle.detailTechnical}>
            <PencilRulerIcon isRound={true} center={true} />
            <br/>
            <p><strong>Fecha de lanzamiento: </strong>2021-01-01</p>
            <p><strong>Número de páginas: </strong>186</p>
            <p><strong>Materiales: </strong>couché semimate de 300 gramos / Holmen de 55 gramos</p>
            <p><strong>Tipografías: </strong>Oswald (Adams, 2008) y Jaqen (Hill, 2019)</p>
          </div>
        </div>
      </MainLayout>
    );
  }
}
